import './style.scss';

import React, { FC, useState } from 'react';

type PageHeaderProps = {
  children: React.ReactNode;
};
const PageHeader: FC<PageHeaderProps> = ({ children }) => {
  return (
    <div className="PageHeader">
      {children}
    </div>
  );
}

export default PageHeader;
