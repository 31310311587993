import "./style.scss";

import { FolderType, ProceduresType } from "../../types/types";
import React, { FC, useContext, useState } from "react";
import { createLinkInvitation, updateFolder } from "../../api/folder";

import { FolderContext } from "../../contexts/FoldersContext";
import { ReactComponent as IconShare } from "../../assets/img/icons/icon_share.svg";
import { ReactComponent as IconUser } from "../../assets/img/icons/icon_User.svg";
import { Link } from "react-router-dom";
import ModalShare from "../ModalShare";
import ProgressBar from "../ProgressBar";
import ToggleButton from "../ToggleButton";

type TableRowProps = {
    folder: FolderType;
    type: string | undefined;
};

const TableRow: FC<TableRowProps> = ({ folder, type }) => {
    const { tryChangeVisibility, tryGetFolder, partners } = useContext(FolderContext);
    const [modalShareIsOpen, setModalShareIsOpen] = useState(false);
    const [shareLink, setShareLink] = useState("");

    const handleChangeVisibility = async (folder: any) => {
        await tryChangeVisibility(folder?.id);
    };

    const openShareModal = async (id: number | undefined) => {
        setModalShareIsOpen(true);
        if (shareLink === "") {
            const response = await createLinkInvitation(type, id);
            setShareLink(response?.data.link);
        }
        await tryGetFolder(type, id?.toString());
    };

    const getProgress = (procedures: ProceduresType[]) => {
        return procedures?.filter((procedure) => procedure.checked).length;
    };

    return (
        <>
            <tr className="FoldersList__item">
                <td>
                    <Link
                        to={`/particuliers/mes-dossiers/${folder?.id}`}
                        className="FoldersList__item__title">
                        {folder?.name}
                    </Link>
                </td>
                <td>
                    {folder?.authorizations?.length > 0 ? (
                        <div className="FoldersList__item__shared">
                            <IconUser
                                fill="#5E89C2"
                                width={16}
                            />
                            <span
                                className="tag tag-info"
                                onClick={() => openShareModal(folder.id)}>
                                {folder?.authorizations?.length} {folder?.authorizations?.length > 9 && "+"}
                            </span>
                        </div>
                    ) : (
                        <div className="FoldersList__item__shared">
                            <IconUser
                                fill="#5E89C2"
                                width={16}
                            />
                            <IconShare
                                fill="#5E89C2"
                                width={16}
                                onClick={() => openShareModal(folder.id)}
                            />
                        </div>
                    )}
                </td>
                <td className="FoldersList__item__secret">
                    <ToggleButton
                        className="FoldersList__item__secret__toggle"
                        checked={!folder?.visibility}
                        icons={false}
                        onChange={() => handleChangeVisibility(folder)}
                    />
                    {modalShareIsOpen && (
                        <ModalShare
                            shareLink={shareLink}
                            isOpen={modalShareIsOpen}
                            onClose={() => setModalShareIsOpen(false)}
                            folder={folder}
                            partners={partners}
                        />
                    )}
                </td>
            </tr>
        </>
    );
};

export default TableRow;
