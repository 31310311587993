import { Route, Routes } from "react-router-dom";

import AddFolder from "./pages/AddFolder";
import Atoms from "./pages/Atoms";
import Dashboard from "./pages/Dashboard";
import DeclarationPerte from "./pages/DeclarationPerte";
import Document from "./pages/Document";
import Faq from "./pages/Faq";
import FilterPage from "./pages/FilterPage";
import Folder from "./pages/Folder";
import IDTracker from "./pages/IDTracker";
import Layout from "./components/Layout";
import ListIDs from "./pages/ListIDs";
import MyFolders from "./pages/MyFolders";
import MyPrograms from "./pages/MyPrograms";
import NotFoundPage from "./pages/NotFoundPage";
import Profil from "./pages/Profil";
import Program from "./pages/Program";
import Verify from "./pages/Verify";
import { FolderContextProvider } from './contexts/FoldersContext';

const ConditionalRoutes = () => {
  const type = localStorage.getItem("type");

  if (type === 'individual') {
    return (
      <Routes>
        <Route
          path="/particuliers/dashboard"
          element={
            <FolderContextProvider userType={type}>
                <Layout>
                    <Dashboard />
                </Layout>
            </FolderContextProvider>
          }
        />
        <Route
          path="/particuliers/pieces-identite"
          element={
            <Layout>
              <ListIDs />
            </Layout>
          }
        />

        <Route
          path="/mes-documents/:id"
          element={
            <Layout>
              <Document />
            </Layout>
          }
        />
        <Route
          path="/particuliers/mes-accompagnements"
          element={
            <Layout>
              <MyPrograms />
            </Layout>
          }
        />
        <Route
          path="/particuliers/mes-accompagnements/ajouter"
          element={
            <Layout>
              <MyPrograms />
            </Layout>
          }
        />
        <Route
          path="/particuliers/mes-accompagnements/:id"
          element={
            <Layout>
              <Program />
            </Layout>
          }
        />
        <Route
          path="/particuliers/mes-dossiers/:id"
          element={
            <FolderContextProvider userType={type}>
                <Layout>
                    <Folder />
                </Layout>
            </FolderContextProvider>
          }
        />
        <Route
          path="/particuliers/mes-dossiers/"
          element={
            <FolderContextProvider userType={type}>
                <Layout>
                <MyFolders />
                </Layout>
            </FolderContextProvider>
          }
        />
        <Route
          path="/particuliers/mes-dossiers/ajouter/"
          element={
            <FolderContextProvider userType={type}>
            <Layout>
              <AddFolder />
            </Layout>
            </FolderContextProvider>
          }
        />
        <Route
          path="/particuliers/idtracker"
          element={
            <Layout>
              <IDTracker />
            </Layout>
          }
        />
        <Route
          path="/particuliers/declarer-une-perte"
          element={
            <Layout>
              <DeclarationPerte />
            </Layout>
          }
        />

        <Route
          path="/particuliers/profil"
          element={
            <Layout>
              <Profil />
            </Layout>
          }
        />
        <Route
          path="/particuliers/verifier-document"
          element={
            <Layout>
              <Verify />
            </Layout>
          }
        />
        <Route
          path="/particuliers/dark-web"
          element={
            <Layout>
              <Verify isDarkWeb />
            </Layout>
          }
        />
        <Route path="/particuliers/faq" element={<Layout ><Faq /></Layout>} />
        <Route path="/particuliers/filter" element={<Layout><FilterPage /></Layout>} />
        <Route path="/particuliers/tests" element={<Atoms />} />
        <Route path="*" element={<Layout><NotFoundPage /></Layout>}></Route>
      </Routes>

      // ... fin des routes pour les particuliers ...
    );
  }
  // else if (type === 'partner') {
  //   return (

  //     <Routes>
  //     <Route
  //       path="/professionnels/dashboard"
  //       element={
  //         <Layout>
  //           <DashboardPro />
  //         </Layout>
  //       }
  //     />
  //     <Route
  //       path="/professionnels/verifier-document"
  //       element={
  //         <Layout>
  //           <VerificationPro/>
  //         </Layout>
  //       }
  //     />
  //     </Routes>

  //     // ... autres routes pour les professionnels ...
  //   );
  // }
  else {
    return (
      <Routes>

      </Routes>
    );
  }

};

export default ConditionalRoutes
