import "./style.scss";

import { FC, useContext } from "react";

import Button from "../Button";
import PriceCard from "../PriceCard";
import { UserContext } from "../../contexts/UserContext";
import logoVerification from "../../assets/img/Verification.svg";

const subjects: any = {
    modify: {
        text: "Modification de mon compte IDProtect",
        body: "Bonjour, Je souhaite modifier les informations de mon compte ID Protect.",
    },
    cancel: {
        text: "Résiliation de mon abonnement IDProtect",
        body: "Bonjour, Je souhaite résilier mon abonnement.",
    },
    buyCredits: {
        text: "Achat de crédits de vérification IDProtect",
        body: "Bonjour, Je souhaite acheter des crédits de vérification.",
    },
};

const BuyCreditsCard: FC = () => {
    const { user } = useContext(UserContext);
    const credits = user?.credits;
    const userInfos = `\n-----------\n ${user?.firstname} ${user?.lastname} \n ${user?.email} \n ${user?.phoneNumber}`;

    const mailTo = (subject: string) => {
        const mail = "contact@idprotect.fr";
        window.location.href = `mailto:${mail}?subject=${subjects[subject].text}&body=${
            subjects[subject].body + encodeURIComponent(userInfos)
        }`;
    };
    return (
        <PriceCard
            title="Crédits"
            logo={logoVerification}
            price={credits?.toString() || "0"}
            subtitle="Vérification d'identité">
            <p>Achetez des crédits pour vérifier la conformité d’un document et assurez vous qu’il n’est pas mis en opposition</p>

            <Button
                label="Acheter"
                size="L"
                onClick={() => mailTo("buyCredits")}
            />
        </PriceCard>
    );
};

export default BuyCreditsCard;
