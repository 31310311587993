import { FolderType, PartnerType, ProceduresType } from "../types/types";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import {
    addFileToFolder,
    changeInvitationDate,
    changeStateProcedure,
    changeVisibility,
    createFolder,
    deleteFile,
    deleteFolder,
    getFolder,
    getFolders,
    removeFolderPermission,
    sendInvitation,
} from "../api/folder";

import { DocumentsContext } from "./DocumentsContext";
import { toast } from "react-toastify";

type FolderContextValue = {
    folders: FolderType[];
    folder: FolderType | undefined;
    listItems: ProceduresType[];
    partners: PartnerType[];
    sharedFolders: FolderType[];
    allFolders: () => Promise<void>;
    tryRemoveFolder: (id: number) => Promise<boolean>;
    tryCreateFolder: (causeOfFraud: string) => Promise<void>;
    tryRemoveFile: (folderId: number, documentId: number | undefined) => Promise<boolean>;
    tryGetFolder: (type: string | undefined, id: string | undefined) => Promise<void>;
    tryChangeVisibility: (folderId: number | undefined) => Promise<void>;
    tryAddFileToFolder: (type: string | undefined, id: string | undefined, file: any, fileType: string) => Promise<void>;
    trySendInvitation: (folderId: number | undefined, email: string, expireDate: string) => Promise<boolean>;
    tryHandleProcedureDone: (procedureId: number, folderId: number | undefined) => Promise<void>;
    tryRemoveFolderPermission: (folderId: number | undefined, userId: number) => Promise<boolean>;
    tryChangeInvitationDate: (authorisationId: number, expireDate: number) => Promise<void>;
};

const FolderContext = createContext<FolderContextValue>({
    folders: [],
    folder: undefined,
    listItems: [],
    partners: [],
    sharedFolders: [],
    allFolders: async () => {},
    tryRemoveFolder: async () => false || true,
    tryCreateFolder: async () => {},
    tryRemoveFile: async () => false || true,
    tryGetFolder: async () => {},
    tryChangeVisibility: async () => {},
    tryAddFileToFolder: async () => {},
    trySendInvitation: async () => false || true,
    tryHandleProcedureDone: async () => {},
    tryRemoveFolderPermission: async () => false || true,
    tryChangeInvitationDate: async () => {},
});

const FolderContextProvider: React.FC<{ children: ReactNode; userType: string }> = ({ children, userType }) => {
    const [folders, setFolders] = useState<FolderType[]>([]);
    const [folder, setFolder] = useState<FolderType>();
    const [listItems, setListItems] = useState<ProceduresType[]>([]);
    const [partners, setPartners] = useState<PartnerType[]>([]);
    const [sharedFolders, setSharedFolders] = useState<FolderType[]>([]);
    const { refreshDocuments } = useContext(DocumentsContext);

    const allFolders = async () => {
        try {
            const folders = await getFolders(userType);
            setFolders(folders.folders);
            setSharedFolders(folders.sharedFolders);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        allFolders();
    }, [userType]);

    const tryCreateFolder = async (causeOfFraud: string) => {
        const response = await createFolder(userType, causeOfFraud);
        if (response?.status === 201) {
            const folders = await getFolders(userType);
            setFolders(folders.folders);
            toast.info("Votre dossier a bien été créé !");
            refreshDocuments();
        } else {
            toast.error("Il y a eu un problème...");
        }
    };

    const tryRemoveFolder = async (id: number) => {
        const response = await deleteFolder(userType, id);
        if (response?.status === 200) {
            setFolders(folders.filter((folder) => folder.id !== id));
            refreshDocuments();
            return true;
        } else {
            return false;
        }
    };

    const tryRemoveFile = async (folderId: number, documentId: number | undefined) => {
        const response = await deleteFile(userType, folderId, documentId);
        if (response?.status === 200) {
            setFolder((prevFolder) => {
                if (!prevFolder) return prevFolder;

                return {
                    ...prevFolder,
                    documents: prevFolder.documents.filter((doc) => doc.id !== documentId),
                };
            });
            setFolders((prevFolders) =>
                prevFolders.map((folder) =>
                    folder.id === folderId
                        ? {
                              ...folder,
                              documents: folder.documents.filter((document) => document.id !== documentId),
                          }
                        : folder,
                ),
            );
            refreshDocuments();
            return true;
        } else {
            return false;
        }
    };

    const tryGetFolder = async (type: string | undefined, id: string | undefined) => {
        const result = await getFolder(type, id);
        setFolder(result?.folder);
        setListItems(result?.listItems);
        setPartners(result?.partners);
    };

    const tryChangeVisibility = async (folderId: number | undefined) => {
        try {
            setFolder((prevFolder) => {
                if (!prevFolder) return prevFolder;

                return {
                    ...prevFolder,
                    visibility: !prevFolder.visibility,
                };
            });
            setFolders((prevFolders) =>
                prevFolders.map((folder) => (folder.id === folderId ? { ...folder, visibility: !folder.visibility } : folder)),
            );
            toast.info("Votre dossier a été modifié");
            await changeVisibility(userType, folderId);
            refreshDocuments();
        } catch (error) {
            console.log(error);
        }
    };

    const tryAddFileToFolder = async (type: string | undefined, id: string | undefined, file: any, fileType: string) => {
        const response = await addFileToFolder(type, id, file, fileType);
        setFolders((prevFolders) =>
            prevFolders.map((folder) =>
                folder.id === response.folderUpdated.id ? { ...folder, documents: [...response.folderUpdated.documents] } : folder,
            ),
        );
        refreshDocuments();
    };

    const trySendInvitation = async (folderId: number | undefined, email: string, expireDate: string) => {
        const response = await sendInvitation(userType, folderId, email, Number(expireDate));
        if (response?.status === 200) {
            const resp = await getFolder(userType, folderId);
            setPartners(resp.partners);

            setFolders((prevFolders) =>
                prevFolders.map((folder) => (folder.id === folderId ? { ...folder, authorizations: resp.partners } : folder)),
            );
            refreshDocuments();
            return true;
        } else {
            return false;
        }
    };

    const tryHandleProcedureDone = async (procedureId: number, folderId: number | undefined) => {
        const response = await changeStateProcedure(procedureId, userType);
        setListItems(response?.data.listItems);
        setFolders((prevFolders) =>
            prevFolders.map((folder) => (folder.id === folderId ? { ...folder, items: response?.data.listItems } : folder)),
        );
    };

    const tryRemoveFolderPermission = async (folderId: number | undefined, userId: number) => {
        const response = await removeFolderPermission(userType, folderId, userId);
        if (response?.status === 200) {
            setPartners(partners.filter((partner) => partner.id !== userId));
            setFolders((prevFolders) =>
                prevFolders.map((folder) =>
                    folder.id === folderId
                        ? {
                              ...folder,
                              authorizations: folder.authorizations.filter((authorization) => authorization.clientId !== userId),
                          }
                        : folder,
                ),
            );
            refreshDocuments();
            return true;
        } else {
            return false;
        }
    };

    const tryChangeInvitationDate = async (authorisationId: number, expireDate: number) => {
        const response = await changeInvitationDate(userType, authorisationId, expireDate);

        setPartners((prevPartners) =>
            prevPartners.map((prevPartner) =>
                prevPartner.authorizationId === authorisationId
                    ? {
                          ...prevPartner,
                          authorizationId: response?.data.authorization.id,
                      }
                    : prevPartner,
            ),
        );
    };

    return (
        <FolderContext.Provider
            value={{
                folders,
                allFolders,
                tryCreateFolder,
                tryRemoveFolder,
                tryRemoveFile,
                tryGetFolder,
                folder,
                tryChangeVisibility,
                tryAddFileToFolder,
                listItems,
                trySendInvitation,
                tryHandleProcedureDone,
                partners,
                tryRemoveFolderPermission,
                sharedFolders,
                tryChangeInvitationDate,
            }}>
            {children}
        </FolderContext.Provider>
    );
};

export { FolderContext, FolderContextProvider };
