import axios from "../../axiosConfig";

export async function createFolder(type, name) {
	try {
		const url = `client/folder/`

		const response = await axios.post(url,{
			type,
			name,
			visibility: "1",
			filesToUpload: ""

		});

		if (response) {
			return response
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getFolders(type) {
	try {
		const url = `client/folders/`
		const data = new FormData();
		data.append("type",type);
		const response = await axios.post(url,data);

		if (response) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function getFolder(type, folderId) {
	try {
		const url = `client/folder/${folderId}`
		const data = new FormData();
		data.append("type",type);
		const response = await axios.post(url,data);

		if (response) {
			return response.data;
		}
	} catch (error) {
		console.error(error);
	}
}

export async function deleteFolder(type, folderId) {
	try {
		const url = `client/folder/${folderId}`
		const response = await axios.delete(url, { data: { type: type } });

		return response;
	} catch (error) {
		console.error(error);
	}
}


export async function addFileToFolder(type, folderId, files, fileType) {
  try {
    const url = `client/add_file_folder/${folderId}`;
    console.log("FileToFoleder", url);
    const data = new FormData();
    data.append("type", type);
    data.append("fileType", fileType);
    data.append("filesToUpload", new File([files], files.name));
    const response = await axios.post(url, data);

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function updateFolder(type,folder) {
  try {
	const url = `client/folder/${folder.id}`;
	const response = await axios.put(url, {type, folder});

	if (response) {
	  console.log(response);
	}
  } catch (error) {
	console.error(error);
  }
}

export async function changeVisibility(type, folderId) {
    try {
      const url = `client/changeVisibility/${folderId}`;
      const response = await axios.put(url, {type});

      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

export async function getFile(clientNumber,folderId,fileName) {
  try {
	  const url = `client/getfile/${clientNumber}/${folderId}/${fileName}`;
      if (folderId) {
        const response = await axios.get(url);
        if (response) {
          return response.data;
        }
      }

  } catch (error) {
	console.error(error);
  }
}


export async function deleteFile(type, folderId, fileId) {
    try {
        const url = `client/file/${folderId}/${fileId}`;
        const response = await axios.delete(url, { data: { type: type } });
        return response;

    } catch (error) {
        console.error(error);
    }
}

export async function sendInvitation(type, folderId, email, expireDate) {
    try {
        const url = `client/send_invitation`;
        const data = new FormData();
        data.append("type", type)
		data.append("folderId", folderId);
		data.append("email", email);
		data.append("expireDate", expireDate);
        const response = await axios.post(url, data);
        return response;

    } catch (error) {
        console.error(error);
    }
}

export async function changeStateProcedure(idProcedure, type) {
    try {
        const url = `client/changeState/${idProcedure}`;
        const data = new FormData();
        data.append("type", type)

        const response = await axios.put(url, data);
        if (response.status === 200) {
            return response;
        }

    } catch (error) {
        console.error(error);
    }
}


export async function createLinkInvitation(type, folderId) {
    try {
        const url = `client/create_link/${folderId}`;
        const data = new FormData();
        data.append("type", type)
        const response = await axios.post(url, data);
        return response;

    } catch (error) {
        console.error(error);
    }
}


export async function removeFolderPermission(type, folderId, userId) {
    try {
        const url = `client/delete_folder_permission`;
        const response = await axios.delete(url, { data: { type: type, folderId: folderId, userInvitedId: userId } });
        return response;

    } catch (error) {
        console.error(error);
    }
}

export async function changeInvitationDate(type, authorisationId, expireDate) {
    try {
        const url = `/client/changeInvitationDate`;
        const data = new FormData();
        data.append("type", type);
        data.append("autorisationId", authorisationId);
        data.append("expireDate", expireDate);

        const response = await axios.put(url, data);

        if (response.status === 200) {
            return response;
        }

    } catch (error) {
        console.error(error);
    }
}