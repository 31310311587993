import { FC } from "react";
import FolderList from "../components/FolderList";
import { FolderType } from "../types/types";
import HeaderSection from "../components/HeaderSection";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";

const folders: FolderType[] = [
  {
    id: 22,
    name: "RENOUVELLEMENT",
    visibility: false,
    system: true,
    adminAccess: true,
    ownerId: 25,
    createdAt: "2023-09-26T12:18:05.000Z",
    updatedAt: "2023-09-26T12:18:05.000Z",
    documents: [],
    authorizations: []
  }
]


const Accompagnements: FC = () => {
  return (
    <>
      <PageHeader>
        <h5>Mes accompagnements</h5>
      </PageHeader>
      <div className="content__body">
        <div className="row">
          <div className="col-lg-9">
            <HeaderSection
              title="Accompagnements"
              number={folders.length} />
            <FolderList folders={folders} />

          </div>
          <div className="col-lg-3">
            <NotificationsList />
          </div>
        </div>
      </div>
    </>
  );
};
export default Accompagnements;
