import { Route, Routes } from "react-router-dom";

import ConditionalRoutes from './ConditionalRoutes'; // Assurez-vous d'importer ce fichier correctement
import DashboardPro from './pages/DashboardPro';
import { DocumentsContextProvider } from "./contexts/DocumentsContext";
import Faq from "./pages/Faq";
import FilterPage from "./pages/FilterPage";
// import VerificationPro from './pages/VerificationPro';
import Layout from './components/Layout/Layout';
import Profil from "./pages/Profil";
import { UserContextProvider } from "./contexts/UserContext";
import Verify from "./pages/Verify";

const ConditionalLayout = () => {
  return (
    <UserContextProvider>
      <Routes>
        <Route
          path="/professionnels/dashboard"
          element={
            <Layout>
              <DashboardPro />
            </Layout>
          }
        />
        <Route
          path="/professionnels/verifier-document"
          element={
            <Layout>
              <Verify />
            </Layout>
          }
        />
        <Route
          path="/professionnels/profil"
          element={
            <Layout>
              <Profil />
            </Layout>
          }
        />
        <Route path="/professionnels/faq" element={<Layout ><Faq /></Layout>} />
        <Route path="/professionnels/filter" element={<Layout><FilterPage /></Layout>} />
        <Route
          path="/professionnels/dark-web"
          element={
            <Layout>
              <Verify isDarkWeb />
            </Layout>
          }
        />
      </Routes>
      <DocumentsContextProvider>
        <ConditionalRoutes />
      </DocumentsContextProvider>
    </UserContextProvider>
  );
};

export default ConditionalLayout;
