import './style.scss';

import { FolderType, PartnerType } from '../../types/types';
import React, { FC, useContext, useState } from 'react';

import Button from "../Button";
import { Button_Style } from '../Button/Button';
import { DocumentsContext } from '../../contexts/DocumentsContext';
import { FolderContext } from '../../contexts/FoldersContext';
import FolderSVG from '../../assets/img/folder.svg';
import { ReactComponent as IconClose } from '../../assets/img/icons/icon_Close.svg';
import { ReactComponent as IconCopy } from '../../assets/img/icons/icon_copy.svg';
import Input from '../Input';
import NotificationsList from '../NotificationsList';
import PartnersList from '../PartnersList';
import Select from '../Select';
import Tabs from '../Tabs';
import ToggleButton from '../ToggleButton';
import { UserContext } from '../../contexts/UserContext';
import { toast } from 'react-toastify';

const options = [
    {
        value: "1 jour",
        label: "1 jour"
    },
    {
        value: "3 jours",
        label: "3 jours"
    },
    {
        value: "5 jours",
        label: "5 jours"
    },
];
type ModalShareProps = {
    isOpen: boolean;
    onClose: (event: React.MouseEvent) => void;
    folder: FolderType | undefined;
    shareLink: string;
    partners: Array<PartnerType>
}

const ModalShare: FC<ModalShareProps> = ({ isOpen, onClose, folder, shareLink, partners }) => {
    const { notificationsUnseen } = useContext(DocumentsContext);
    const { trySendInvitation, tryChangeVisibility } = useContext(FolderContext);
    const [modalOpen, setModalOpen] = useState(isOpen);
    const [email, setEmail] = useState("");
    const [validity, setValidity] = useState("");

    const tabs = [
        {
            label: "Partage",
            notification: false,
            content: (
                <>
                    <div>
                        <Input
                            placeholder="Entrez l'adresse Mail"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {email !== "" ?
                            <Select
                                options={options}
                                value={validity}
                                placeholder="Temps de validité"
                                handleChange={(value) => setValidity(value)} /> :
                            <></>
                        }
                    </div>
                    <div>
                        {Array.isArray(partners) && partners.length > 0 && (<PartnersList partners={partners} folder={folder}></PartnersList>)}
                    </div>
                </>
            ),
        },
        {
            label: "Notifications",
            notification: notificationsUnseen > 0 ? true : false,
            content: (
                <NotificationsList fromDashBoard={false} />
            ),
        },
    ];

    const handleClose = (event: React.MouseEvent) => {
        setModalOpen(false);
        onClose(event);
    }

    const handleSecret = async () => {
        await tryChangeVisibility(folder?.id)
    }

    const isDisbaled = () => {
        return !(email !== "" && validity !== "")
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareLink).then(() => {
            toast.info("Votre lien d'invitation a bien été copié", {
                position: "top-center"
            })
        }).catch((err) => {
            console.error('Failed to copy the link: ', err);
        });
    }

    const handleInvite = async () => {
        const response = await trySendInvitation(folder?.id, email, validity.split("")[0]);
        if (response) {
            toast.info(`Vous partagez votre dossier avec ${email}`, {
                position: "top-center"
            })
        } else {
            toast.error(`Il y a eu un problème lors du partage avec ${email}`, {
                position: "top-center"
            })
        }
        // Reset form to invite
        setEmail("")
        setValidity("")
    }
    return (
        <div>
            <div className="modal--backdrop" onClick={handleClose} />
            <div className={`modal_share modal--info`}>
                <div className="modal_share__header">
                    <button className="modal_share__header__cross" onClick={handleClose}><IconClose fill="#ffff" width={24} height={24} /></button>
                </div>
                <div className="modal_share__body">
                    <div className="modal_share__body__content">
                        <img src={FolderSVG} alt="dossier" />
                        <h4 className="modal_share__body__content__title">{folder?.name}</h4>
                        {/* <Button
                            className="modal_share__body__content__copy"
                            label="Copy link"
                            icon={<IconCopy/>}
                            style={Button_Style.OUTLINED}
                            onClick={handleCopyLink}
                        /> */}
                        <div className='modal_share__body__content__box'>
                            <Tabs tabs={tabs} />
                        </div>
                    </div>
                    <div className="modal_share__footer">
                        <Button
                            disabled={isDisbaled()}
                            label="Inviter"
                            style={Button_Style.OUTLINED}
                            size="L"
                            onClick={handleInvite}
                        />
                        <div className="modal_share__footer__secret">
                            <div>Rendre secret</div>
                            <ToggleButton
                                checked={!folder?.visibility}
                                icons={false}
                                onChange={() => handleSecret()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ModalShare;
