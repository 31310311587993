import "./style.scss";

import { Button_Style, Button_Type } from '../Button/Button';
import { FC, useState } from "react";

import Button from "../Button";
import { ReactComponent as IconClose } from '../../assets/img/icons/icon_Close.svg';

type ModalProps = {
	children?: React.ReactNode;
	type?: "info" | "success" | "error" | "warning";
	title: string;
	isOpen: boolean;
	onClose: (event: React.MouseEvent) => void;
	onConfirm?: (event: React.MouseEvent) => void;
	labelOnConfirm?: string;
	confirmButtonDisabled?: boolean;
}
const Modal: FC<ModalProps> = ({ isOpen, onClose, title, type, children, onConfirm, labelOnConfirm, confirmButtonDisabled }) => {
	const [modalOpen, setModalOpen] = useState(isOpen);
	const handleClose = (event: React.MouseEvent) => {
		setModalOpen(false);
		onClose(event);
	}
	const handleConfirm = async (event: React.MouseEvent) => {
		if (onConfirm) {
			try {
				await onConfirm(event);
			} catch (error) {
				// Handle the error from onConfirm here
				console.error("Error during confirmation:", error);
			}
		} else {
			// setModalOpen(false);
			// onClose();
		}
	};
	return (
		<div>
			<div className="modal--backdrop" onClick={handleClose} />
			<div className={`modal modal--${type ? type : 'info'} `}>
				<div className="modal__header">
					<h3 className="modal__header__title">{title}</h3>
					<button onClick={handleClose}><IconClose fill="#384050" width={16} height={16} /></button>
				</div>
				<div className="modal__body">
					{children}
				</div>
				<div className="modal__footer">
					<Button
						label="Annuler"
						style={Button_Style.OUTLINED}
						onClick={handleClose}
						type={type === "error" ? Button_Type.Error : type === "success" ? Button_Type.SUCCESS : Button_Type.PRIMARY} />
					<Button
						label={labelOnConfirm ? labelOnConfirm : 'ok'}
						onClick={(e) => { handleConfirm(e) }}
						disabled={confirmButtonDisabled}
						type={type === "error" ? Button_Type.Error : type === "success" ? Button_Type.SUCCESS : Button_Type.PRIMARY} />
				</div>
			</div>
		</div>

	)
}
export default Modal;
