import apiInstance from '../../axiosConfig';
import axios from "../../axiosConfig";

// eslint-disable-next-line react-hooks/rules-of-hooks

export async function login(email, password) {
    const response = await apiInstance.post('/client/login', { email, password });
    if (response) {
      localStorage.setItem('type', response.data.type);
    }
    return response;
}

export async function logout() {
  try {
    const response = await apiInstance.get('/logout')
    if (response) {
      localStorage.removeItem('type');
    }
  } catch (e) {
    console.log(e)
  }
}



export async function getUser(type) {
  try {
    const response = await apiInstance.post('/client/account', { type });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function userConfirmation(token) {
	const response = await axios.post('/client/confirmation',
		{ token: token });
    return response.data;
}

export async function checkPassword(id,password, type) {
	const url = '/client/checkPassword';
	let data = {
		type,
		id,
		password
	}

	return axios.post(url,data);

}

// export async function userConfirmation (token) {
//   const body = new FormData()
//   body.append('token', token)
//   const response = await this.$axios.$post('/api/client/confirmation', body)
//   if (response) {
//     return response
//   }
// }
