/* eslint-disable react/style-prop-object */
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import EmptyContent from '../components/EmptyContent';
import File from '../components/File';
import FileUploader from '../components/FileUploader';
import { FolderContext } from '../contexts/FoldersContext';
import { ReactComponent as IconFolder } from '../assets/img/icons/icon_folder.svg';
import Modal from '../components/Modal';
import ModalShare from '../components/ModalShare';
import NotificationsList from '../components/NotificationsList';
import PageHeader from '../components/PageHeader'
import SubHead from '../components/SubHead';
import { UserContext } from '../contexts/UserContext';
import { createLinkInvitation } from '../api/folder'
import { toast } from 'react-toastify';

const Program = () => {
	const { user } = useContext(UserContext);
	const { folder, tryRemoveFolder, tryGetFolder, tryChangeVisibility, tryAddFileToFolder, partners } = useContext(FolderContext);
	const type = user?.type;
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
	const [modalShareIsOpen, setModalShareIsOpen] = useState(false);
	const [shareLink, setShareLink] = useState("");
	const navigate = useNavigate();
	let { id } = useParams();
	useEffect(() => {
		const fetchFolder = async () => {
			await tryGetFolder(type, id);
		}
		fetchFolder();
		const tryCreateShareLink = async () => {
			const response = await createLinkInvitation(type, id);
			setShareLink(response?.data.link);
		};
		tryCreateShareLink();
	}, [id, type]);

	const handleUploadFile = async (files: any, fileType: string) => {
		try {
			await tryAddFileToFolder(type, id, files[0], fileType);
			// Fetch folder when add folder because reactivity doesn't work on the response addFolder
			await tryGetFolder(type, folder?.id.toString())

		} catch (error) {
			console.log(error);
		}
	}
	const handleReturn = () => {
		navigate(-1);
	};

	const fileUploaderProps = {
		className: "mb-0",
		title: "Ajouter",
		allowedFormats: 'application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		onDelete: () => console.log('delete'),
		isList: false,
	};


	const handleChangeVisibility = async () => {
		await tryChangeVisibility(folder?.id)
	}

	const handleShareFolder = async () => {
		setModalShareIsOpen(true)
	}

	return (
		<>
			<PageHeader>
				<h5>Mes dossiers /<IconFolder fill="#1956A8" />{folder?.name}</h5>
			</PageHeader>
			<div className="content__body">
				<div className="row">
					<div className="col-lg-8">
						{folder &&
							<SubHead
								folderName={folder.name}
								folderId={folder.id}
								folderVisibility={folder.visibility}
								handleReturn={handleReturn}
								handleSecret={handleChangeVisibility}
								handleShareFolder={handleShareFolder}
								handleDeleteFolder={() => setConfirmModalIsOpen(true)}
							/>
						}
						<div>
							{/* <HeaderSection title={documentType.title} number="" /> */}
							<div className="d-flex gap-1 mb-2 " style={{ flexWrap: 'wrap' }}>
								<FileUploader {...fileUploaderProps} onChange={(files) => handleUploadFile(files, "VERBAL")} />
								{folder?.documents && folder?.documents?.length > 0 ?
									folder?.documents.map((file: any, index: any) => <File key={index} file={file} />)
									: <EmptyContent text="Aucun document dans ce dossier" />}
							</div>
						</div>

					</div>
					<div className="col-lg-4">
						<NotificationsList filter='folder' />
					</div>
				</div>
				{confirmModalIsOpen &&
					<Modal
						title='Supprimer un dossier'
						type='error'
						labelOnConfirm='Supprimer'
						isOpen={confirmModalIsOpen}
						onClose={() => setConfirmModalIsOpen(false)}
						onConfirm={async () => {
							const response = await tryRemoveFolder(Number(id))
							if (response) {
								navigate(-1);
								toast.info("Votre dossier a bien été supprimé", {
									position: "top-center"
								});
							} else {
								toast.error('Il y a eu un problème...', {
									position: "top-center"
								})
							}
						}}>
						<p>Êtes vous sûr de vouloir supprimer ce dossier ? La suppression est définitive.</p>
					</Modal>}
				{modalShareIsOpen &&
					<ModalShare shareLink={shareLink} isOpen={modalShareIsOpen} onClose={() => setModalShareIsOpen(false)} folder={folder} partners={partners} />}
			</div>
		</>
	)
}

export default Program
