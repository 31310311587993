import { FC } from "react";

const DeclarationPerte: FC = () => {
	return (
		<div className="declarationperte">
			DeclarationPerte
		</div>
	)
}

export default DeclarationPerte;