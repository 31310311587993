import "./style.scss";

import React, { FC, useState } from "react";

type PriceCardProps = {
  title: string;
  logo: string;
  price: string;
  subtitle?: string;
  children: React.ReactNode;
  monthly?: boolean;
};
const ProfilCard: FC<PriceCardProps> = ({
  title,
  logo,
  price,
  subtitle,
  children,
  monthly
}) => {
  return (
    <div className="PriceCard">
      <div className="PriceCard__header">
        <h2>{title}</h2>
        <div className="pl">
          <div>
            <h3 className={monthly ? "mb-0" : ""}>{price}</h3>
            {monthly && <p>/mois</p>}
          </div>
          <img src={logo} alt="" />
        </div>
        <h4>{subtitle}</h4>
      </div>
      <div className="PriceCard__body">{children}</div>
    </div>
  );
};

export default ProfilCard;
