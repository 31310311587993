/* eslint-disable react/style-prop-object */
import './style.scss';

import { Button_Style, Button_Type } from '../Button/Button';
import { FC, useContext, useState } from 'react';

import Alert from '../Alert';
import Button from '../Button';
import { DocumentBasylic } from '../../types/types';
import DocumentPreview from '../DocumentPreview';
import { FolderContext } from '../../contexts/FoldersContext';
import Loader from '../Loader';
import Select from '../Select';
import Skeleton from '../Skeleton';
import { UserContext } from '../../contexts/UserContext';
import { createFolder } from '../../api/folder';

const options = [
  {
    value: "J'ai perdu de l'argent",
    label: "J'ai perdu de l'argent"
  },
  {
    value: "Amende SNCF/RAPT",
    label: "Amende SNCF/RAPT"
  },
  {
    value: "Infraction routière",
    label: "Infraction routière"
  },
  {
    value: "Quelqu'un a ouvert un contrat en mon nom",
    label: "Quelqu'un a ouvert un contrat en mon nom"
  },
  {
    value: "Leasing",
    label: "Leasing"
  },
  {
    value: "Quelqu'un a ouvert une société en mon nom",
    label: "Quelqu'un a ouvert une société en mon nom"
  },
  {
    value: "Quelqu'un usurpe mon identité sur les réseaux sociaux",
    label: "Quelqu'un usurpe mon identité sur les réseaux sociaux"
  },
  {
    value: "Je suis connu des services de police/judiciaire/financier",
    label: "Je suis connu des services de police/judiciaire/financier"
  }
]

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
type ValidateFolderProps = {
  isScanning: boolean;
  documentVerified?: DocumentBasylic;
  refresh?: () => void;
  verifyReportURL?: string;
  errorMessage?: string;
  isDarkWeb?: boolean;
};
const ValidateFolder: FC<ValidateFolderProps> = ({ isScanning, documentVerified, errorMessage }) => {
  const [isUsedForFraud, setIsUsedForFraud] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [causeOfFraud, setCauseOfFraud] = useState<string>('');

  const { user } = useContext(UserContext);
  const { tryCreateFolder } = useContext(FolderContext);
  const type = user?.type;
  return (
    <div className="ValidateFolder card">
      <h4>Justificatif d'identité</h4>
      <h5>{isScanning ? 'Analyse du document' : 'Document enregistré'}</h5>
      {isScanning ? (
        <>
          {isMobile && (
            <Loader text="Analyse en cours. L'analyse peut prendre jusqu'à 30secondes." />
          )}
          <Skeleton width="90%" height={28} />
          <Skeleton width="100%" height={228} />
          <Skeleton width="50%" height={26} />
          <Skeleton width="90%" height={40} />

        </>) :
        (
          <>
            {documentVerified && (
              <div className='d-flex justify-content-between flex-column '>
                <div>
                  {errorMessage && <Alert type='error'>{errorMessage}</Alert>}
                  <DocumentPreview document={documentVerified} />
                  <h6>Cause de l'usurpation</h6>
                  <p className='mb-1'>Pour une assistance sur-mesure, nous avons besoin de précision sur la situation </p>
                  <Select
                    options={options}
                    value={causeOfFraud}
                    handleChange={setCauseOfFraud}
                    placeholder="Cas de l'usurpation" />

                  <p className='mt-2 select-label'>Ce document a-t-il servi à la fraude ?</p>
                  <div className="d-flex gap-1  mb-1">
                    <Button label='Oui' style={!isUsedForFraud ? Button_Style.OUTLINED : Button_Style.SOLID} onClick={() => setIsUsedForFraud(true)} />
                    <Button label='Non' style={isUsedForFraud ? Button_Style.OUTLINED : Button_Style.SOLID} onClick={() => setIsUsedForFraud(false)} />
                  </div>

                  {isUsedForFraud && <>
                    <p className='select-label'>Souhaitez-vous le mettre en opposition ?</p>
                    <div className="d-flex gap-1 mb-1">
                      <Button label='Oui' style={!isBlocked ? Button_Style.OUTLINED : Button_Style.SOLID} onClick={() => setIsBlocked(true)} />
                      <Button label='Non' style={isBlocked ? Button_Style.OUTLINED : Button_Style.SOLID} onClick={() => setIsBlocked(false)} />
                    </div></>
                  }
                </div>
                <div className="d-flex justify-content-between mt-auto">
                  <Button label='Annuler' style={Button_Style.OUTLINED} />
                  <Button label='Valider' style={Button_Style.SOLID} type={Button_Type.SUCCESS} disabled={!causeOfFraud} onClick={() => tryCreateFolder(causeOfFraud)} />
                </div>
              </div>
            )}
          </>
        )}
    </div>
  );
}

export default ValidateFolder;
