import Button, { Button_Style, Button_Type } from "../components/Button/Button";
import { FC, useState } from "react";

import Alert from "../components/Alert/Alert";
import Checkbox from "../components/Checkbox/Checkbox";
import { ReactComponent as IconChat } from "../assets/img/icons/icon_User.svg";
import { ReactComponent as IconEye } from "../assets/img/icons/icon_Eye.svg";
import Input from "../components/Input";
import Modal from "../components/Modal";
import Select from "../components/Select/Select";

const Atoms: FC = () => {
	const [showModal, setShowModal] = useState(false);

	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};
	return <div style={{ background: 'white', padding: '15px' }}>
		<h1>Atoms</h1>
		<div className="row">
			<div className="col-sm-3">
				<h2>Buttons</h2>
				<Button label="Default s" size="S" icon={<IconEye />} />
				<Button label="Default m " icon={<IconChat />} />
				<Button label="Default l" size="L" icon={<IconEye />} />
				<Button label="Default l" type={Button_Type.SECONDARY} icon={<IconEye />} />

				<Button label="Error" type={Button_Type.Error} icon={<IconEye />} />
				<Button label="Success" type={Button_Type.SUCCESS} icon={<IconEye />} />

				<Button label="Primary" size="M" style={Button_Style.OUTLINED} type={Button_Type.PRIMARY} icon={<IconEye />} />
				<Button label="Secondary" size="M" style={Button_Style.OUTLINED} type={Button_Type.Error} icon={<IconEye />} />
				<Button label="Secondary" size="M" style={Button_Style.OUTLINED} type={Button_Type.SUCCESS} icon={<IconEye />} />

				<Button label="Secondary" size="M" style={Button_Style.OUTLINED} type={Button_Type.SECONDARY} icon={<IconEye />} />

				<Button label="Label" size="M" style={Button_Style.SOLID} type={Button_Type.LABEL} />

			</div>
			<div className="col-sm-3">
				<h2>Inputs + Select</h2>
				<Input placeholder="Text" type="text" />
				<Input placeholder="Text" type="text" className="error" />
				<Input placeholder="Text" type="text" className="success" />
				<Input placeholder="Number" type="number" />
				<Select options={[{ value: "Toto", label: "Toto" }]} placeholder="Selection" />
			</div>
			<div className="col-sm-3">
				<h2>Tags</h2>
				<div style={{ display: 'flex', gap: '8px' }}>
					<span className="tag tag-success">Success</span>
					<span className="tag tag-error">Error</span>
					<span className="tag tag-warning">Warning</span>
					<span className="tag tag-info">Info</span>

				</div>
			</div>
			<div className="col-sm-3">
				<h2>Alerts</h2>
				<Alert type="info">Mon Info</Alert>
				<Alert type="success">Mon Success</Alert>
				<Alert type="error">Mon Error</Alert>
				<Alert type="warning">Mon Warning</Alert>
			</div>
			<div className="col-sm-3">
				<h2>Checkboxes</h2>
				<Checkbox />
				<Checkbox label="Label" />
			</div>
		</div>
		<h2>Organisms</h2>
		<div className="row">
			<div className="col-sm-6">
				<Button label="open modal" onClick={handleOpenModal} />
				{showModal && <Modal title="Modal" isOpen={showModal} onClose={handleCloseModal}>
					Curabitur blandit tempus porttitor. Donec id elit non mi porta gravida at eget metus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
				</Modal>}
			</div>
		</div>
	</div>;
}
export default Atoms

