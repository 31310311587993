import "./style.scss";

import Button, { Button_Style, Button_Type } from "../Button/Button";
import { DocumentBasylic, ErrorType } from "../../types/types";
import { FC, useContext, useEffect, useState } from "react";
import {
    extractData,
    getDocumentAnalysisFile,
    getDocumentHighestAnalysisEver,
    getDocumentHighestAnalysisEverPermis,
    getVerifationToken,
} from "../../api/analysis";

import Alert from "../Alert";
import DocumentTypeSelect from "../DocumentTypeSelect";
import DocumentUpload from "../DocumentUpload/DocumentUpload";
import FileDownloadInstructions from "../FileDownloadInstructions";
import ShareIDVerification from "../ShareIDVerification";
import { UserContext } from "../../contexts/UserContext";

type AddDocumentFormProps = {
    onChangeType: (type: string) => void;
    type: string;
    setIsScanning: (isScanning: boolean) => void;
    allowForeignDocuments?: boolean;
    isScanning?: boolean;
    setErrorMessage?: (errorMessage: string) => void;
    setIsScanned?: (isScanned: boolean) => void;
    setDocument?: (document: DocumentBasylic) => void;
    isVerification?: boolean;
    setErrorType?: (errorType: ErrorType) => void;
    errorType?: ErrorType;
    setVerifyReportURL?: (url: string) => void;
    isDarkWeb?: boolean;
};

const AddDocumentForm: FC<AddDocumentFormProps> = ({
    onChangeType,
    setIsScanning,
    type,
    allowForeignDocuments,
    setErrorMessage,
    setIsScanned,
    setDocument,
    isScanning,
    isVerification,
    setErrorType,
    errorType,
    setVerifyReportURL,
    isDarkWeb,
}) => {
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [requiredFiles, setRequiredFiles] = useState<number>(1);
    const [isFrench, setIsFrench] = useState<boolean>(true);
    const { user } = useContext(UserContext);
    const [isShareID, setIsShareID] = useState<boolean>(true);
    const [accessToken, setAccessToken] = useState<any>("");
    const userType = user?.type;

    const handleChange = (value: string) => {
        onChangeType(value);
        setSelectedFiles([]);
        if (value === "Passeport") {
            setRequiredFiles(1);
        } else {
            setRequiredFiles(1);
        }
    };

    const generateURL = async () => {
        try {
            const responseFile = await getDocumentAnalysisFile(userType, selectedFiles);
            setVerifyReportURL && setVerifyReportURL(responseFile.data.protectIDReportPdf);
        } catch (error: any) {
            console.error("Une erreur s'est produite :", error);
            setErrorMessage && setErrorMessage(error.message);
        }
    };

    const handleVerification = async () => {
        setIsScanning(true);
        try {
            let response;
            if (type === "Permis de conduire") {
                response = await getDocumentHighestAnalysisEverPermis(userType, selectedFiles);
            } else {
                response = await getDocumentHighestAnalysisEver(userType, selectedFiles);
            }
            generateURL();
            setIsScanned && setIsScanned(true);
            setDocument && setDocument(response.data.success);
        } catch (error: any) {
            handleErrorVerification(error);
            generateURL();
        } finally {
            setIsScanning(false);
        }
    };

    const handleDarkWebVerification = async () => {
        setIsScanning(true);
        try {
            const response = await extractData(userType, selectedFiles);
            generateURL();
            setIsScanned && setIsScanned(true);
            setDocument && setDocument(response.data.success);
        } catch (error: any) {
            handleErrorVerification(error);
        } finally {
            setIsScanning(false);
        }
    };

    const handleErrorVerification = (error: any) => {
        const errorStatus = error.response?.status;
        const responseError = error.response?.data?.error;
        const error400Message =
            responseError === "Document manquantes, Veuillez présenter un autre document d'idnetité."
                ? "Document non reconnu."
                : "Document non conforme.";

        const errors: ErrorType[] = [
            {
                status: 500,
                message: "Erreur de serveur interne",
            },
            {
                status: 504,
                message: "L'analyse de votre document prend plus de temps que prévu. Veuillez réessayer ultérieurement.",
            },
            {
                status: 503,
                message: responseError,
            },
            {
                status: 400,
                message: error400Message,
            },
            {
                status: 401,
                message: "Une erreur est survenue. Veuillez réessayer.",
            },
        ];
        // Si l'error.status n'est pas dans la liste des erreurs, on affiche un message par défaut
        const errorType = errors.find((error) => error.status === errorStatus) || {
            status: 3000,
            message: "Une erreur est survenue. Veuillez réessayer.",
        };

        //  const errorType = errors.filter((error: any) => error.status === errorStatus)[0];

        setErrorType && setErrorType(errorType);
    };

    useEffect(() => {
        if (!isShareID) return;
        const getToken = async () => {
            try {
                const token = await getVerifationToken(userType);
                setAccessToken(token.data.payload.access_token);
            } catch (error: any) {
                console.error(error.response.statusText);
            }
        };
        getToken();
    }, [userType]);

    useEffect(() => {
        const sdkFlex: any = document.querySelector(".videoVerification .sdk-flex");
        if (!sdkFlex) return;
        sdkFlex.style.display = "none";
    }, [accessToken]);

    return (
        <div className="addDocumentForm card">
            {isShareID && !isDarkWeb ? (
                <div className="videoVerification">
                    <ShareIDVerification
                        accessToken={accessToken}
                        setIsShareID={setIsShareID}
                        setIsScanning={setIsScanning}
                        setIsScanned={setIsScanned}
                        setErrorType={setErrorType}
                        setDocument={setDocument}
                        errorType={errorType}
                    />
                </div>
            ) : (
                <>
                    <div>
                        {errorType?.status !== 0 && <Alert type="error">{errorType?.message}</Alert>}
                        <DocumentTypeSelect
                            handleChange={handleChange}
                            isVerification={isVerification}
                            type={type}
                        />
                        <div className="addDocumentForm__buttons">
                            <FileDownloadInstructions />
                            {type && (
                                <DocumentUpload
                                    type={type}
                                    setSelectedFiles={setSelectedFiles}
                                    selectedFiles={selectedFiles}
                                />
                            )}
                        </div>
                        {!isFrench && !allowForeignDocuments && (
                            <Alert type="error">
                                Attention, les documents non français ne sont pas encore pris en charge. Veuillez nous contacter pour plus
                                d'informations.
                            </Alert>
                        )}
                    </div>
                    <Button
                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                        label={isVerification ? "Vérifier" : "Ajouter"}
                        style={Button_Style.OUTLINED}
                        disabled={selectedFiles.length < requiredFiles || (!isFrench && !allowForeignDocuments)}
                        onClick={() => (isDarkWeb ? handleDarkWebVerification() : handleVerification())}
                    />
                </>
            )}
        </div>
    );
};

export default AddDocumentForm;
