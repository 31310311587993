import './style.scss';

import { Button_Style, Button_Type } from '../Button/Button';
import React, { FC, useContext, useEffect, useState } from 'react';
import { createLinkInvitation, getFile } from '../../api/folder';

import Button from '../Button';
import { FolderContext } from '../../contexts/FoldersContext';
import FolderSVG from '../../assets/img/folder.svg';
import { FolderType } from '../../types/types';
import Hug from '../Hug';
import { ReactComponent as IconMore } from '../../assets/img/icons/icon_more.svg';
import JSZip from 'jszip';
import Modal from '../Modal';
import ModalShare from '../ModalShare';
import { UserContext } from '../../contexts/UserContext';
import apiInstance from '../../axiosConfig';
import { saveAs } from "file-saver";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type FolderCardProps = {
    folder: FolderType,
    id: number,
    name: string,
    pathname: string,
};

const FolderCard: FC<FolderCardProps> = ({ name, id, pathname, folder }) => {
    const { user } = useContext(UserContext);
    const type = user?.type;
    const { tryRemoveFolder, tryChangeVisibility, tryGetFolder, partners } = useContext(FolderContext);
    const navigate = useNavigate();
    const [seeMore, setSeeMore] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [modalShareIsOpen, setModalShareIsOpen] = useState(false);
    const [shareLink, setShareLink] = useState("");
    const baseUrl = apiInstance.getUri();

    // close the seeMore modal when the user clicks outside of the modal
    useEffect(() => {
        document.addEventListener('click', () => {
            setSeeMore(false);
        });
        return () => {
            document.removeEventListener('click', () => {
                setSeeMore(false);
            });
        }
    }, [])

    const handleDocumentClick = (id: number) => {
        navigate(`/particuliers/${pathname}/${id.toString()}`);
    };
    const openMore = async (event: React.MouseEvent) => {
        event.stopPropagation();
        if (shareLink === "") {
            const response = await createLinkInvitation(type, id);
            setShareLink(response?.data.link);
        }
        await tryGetFolder(type, id?.toString());
        setSeeMore(!seeMore)
    }

    const handleShare = () => {
        setModalShareIsOpen(true)

    }
    const handleSecret = async () => {
        await tryChangeVisibility(Number(id))
    }
    const handleDelete = async () => {
        setConfirmModalIsOpen(true);
    }

    const handleDownload = async () => {
        setSeeMore(!seeMore);
        const zip = new JSZip();
        const folderZip = zip.folder(name);

        const filePromises = folder.documents.map(async (document) => {
            const response = await fetch(`${baseUrl}/client/getfile/${user?.clientNumber}/${folder.id}/${document.name}`);
            const blob = await response.blob();

            folderZip?.file(document.name, blob);
        })
        await Promise.all(filePromises);
        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `${folder.name}.zip`);
        });
    }

    return (
        <div key={id} className="folder_card">
            <div className="folder_card_content" onClick={() => handleDocumentClick(id)}>
                {/* {status && <span className={`tag ${status === 'Terminé' ? 'tag-success' : 'tag-warning'}`} >
            {status}
          </span>} */}
                <img src={FolderSVG} alt="dossier" />
                <div className="folder_card_content_title">{name}</div>
            </div>
            <Button className="folder_card_content_more" icon={<IconMore />} type={Button_Type.PRIMARY} style={Button_Style.SOLID} size="more" onClick={(e) => openMore(e)} />
            {seeMore &&
                <Hug
                    onShareClick={handleShare}
                    onDownloadClick={handleDownload}
                    onSecretClick={handleSecret}
                    onDeleteClick={handleDelete}
                />
            }
            {confirmModalIsOpen &&
                <Modal
                    title='Supprimer un dossier'
                    type='error'
                    labelOnConfirm='Supprimer'
                    isOpen={confirmModalIsOpen}
                    onClose={(e) => {
                        e.stopPropagation();
                        setConfirmModalIsOpen(false)
                        setSeeMore(false)
                    }
                    }
                    onConfirm={async (e) => {
                        e.stopPropagation();
                        const response = await tryRemoveFolder(Number(id))
                        if (response) {
                            toast.info("Votre dossier a bien été supprimé", {
                                position: "top-center"
                            });
                        } else {
                            toast.error('Il y a eu un problème...', {
                                position: "top-center"
                            })
                        }
                    }}>
                    <p>Êtes vous sûr de vouloir supprimer ce dossier ? La suppression est définitive.</p>
                </Modal>}
            {modalShareIsOpen &&
                <ModalShare shareLink={shareLink} isOpen={modalShareIsOpen} onClose={() => setModalShareIsOpen(false)} folder={folder} partners={partners} />}
        </div>
    );
}

export default FolderCard;
