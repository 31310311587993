import { createContext, useEffect, useState } from "react";
import { getUser, logout } from "../api/auth";

import Loader from "../components/Loader";
import { NotificationType } from "../types/types";
import { User } from "../types/types";
import { getFollowedDocuments } from "../api/followed";
import { getNotifications } from "../api/notifications";
import { useNavigate } from "react-router-dom";

type UserContextValue = {
    user: User | null;
    userType: string | null;
    token: string | null;
    notifications: NotificationType[] | null;
    isVerified: boolean;
    setToken: (token: string | null) => void;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    getMe: React.Dispatch<React.SetStateAction<User | null>>;
    logoutFonction: () => void;
    logout: (token: string | null) => Promise<void>;
};

const UserContext = createContext<UserContextValue>({
    user: null,
    userType: null,
    notifications: null,
    token: null,
    isVerified: false,
    setToken: () => {},
    setUser: () => {},
    logoutFonction: () => {},
    logout: async () => {},
    getMe: async () => {},
});

type UserContextProviderProps = {
    children: React.ReactNode;
};

const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }: any) => {
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [userType, setUserType] = useState<string | null>(null);
    const [isVerified, setIsVerified] = useState(false);
    const [notifications, setNotifications] = useState<any[] | null>(null); // Replace 'any' with the actual type of notifications
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const userType = localStorage.getItem("type");
        if (!userType) {
            navigate("/");
        }
    }, [token, navigate]);

    useEffect(() => {
        const localType = localStorage.getItem("type");
        if (localType) {
            setToken(localType);
            setLoading(false);
            setUserType(localType);
        }
    }, []);
    useEffect(() => {
        getVerifiedDocument();
    }, [userType]);

    useEffect(() => {
        if (token) {
            localStorage.setItem("type", token);
            getMe();
            getUserNotifications();
        }
    }, [token]);

    const getMe = async () => {
        const userResponse = await getUser(token);
        setUser(userResponse);
    };

    const getUserNotifications = async () => {
        try {
            const request = await getNotifications(token);
            if (Array.isArray(request)) {
                setNotifications(request);
            }
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
        }
        return notifications;
    };

    const getVerifiedDocument = async () => {
        try {
            const response = await getFollowedDocuments(userType);
            const isVerified = response.some((document: any) => document.stateDoc === "SUIVI");
            setIsVerified(isVerified);
        } catch (error) {
            console.error("Failed to fetch verified document:", error);
        }
    };

    const logoutFonction = async () => {
        await logout();
        localStorage.removeItem("token");
        navigate("/");

        setUser(null);
    };

    const value: UserContextValue = {
        user,
        setUser,
        userType,
        notifications,
        token,
        isVerified,
        getMe,
        setToken,
        logoutFonction,
        logout,
    };

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
