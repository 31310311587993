import "./style.scss";

import Button from "../Button";
import { Button_Type } from "../Button/Button";
import { FC } from "react";
import frenchTech from "../../assets/img/Logo_French_Tech.png";
import illu from "../../assets/img/Illustration_IDS.png";
import { useNavigate } from "react-router-dom";

const Home: FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="home">
                <div className="container row">
                    <div className="col-lg-7 home__content">
                        <h1 className="home__title">
                            Le 1er moyen de <br />
                            prévention contre <br />{" "}
                            <span className="home__title__tag">
                                <span className="gradient">l'usurpation</span> <span className="color">d'identité</span>
                            </span>
                        </h1>
                        <h2 className="home__subtitle">L'application ID Protect</h2>
                        <div className="home__desc">
                            <p>ID Protect prévient l’usurpation d’identité grâce à des outils innovants.</p>
                            <p>Particuliers, protégez vos documents et facilitez vos démarches en ligne .</p>
                            <p>Professionnels, prévenez facilement les cas d’usurpation.</p>
                        </div>
                        <div className="home__buttons">
                            <Button
                                label="Particuliers"
                                onClick={() => navigate("/particuliers/login")}
                            />
                            <Button
                                label="Professionnels"
                                type={Button_Type.SECONDARY}
                                onClick={() => navigate("/professionnels/login")}
                            />
                        </div>
                    </div>
                    <div className="col-lg-5 home__illu">
                        <img
                            src={frenchTech}
                            alt="Logo French Tech"
                        />
                        <img
                            src={illu}
                            alt="ID Protect Documents d'identité"
                            className="mt-auto d-block"
                        />
                    </div>
                </div>
                {/* <Snackbar title="Vous souhaitez vous protéger contre l’usurpation d’identité ?" expanded={false}>
          <p>Inscrivez vous à notre newsletter</p>
          <Input placeholder="email" type="email" value="" />
          <Button type='primary' style={Button_Style.OUTLINED} label='envoyer' />
        </Snackbar> */}
            </div>
        </>
    );
};

export default Home;
