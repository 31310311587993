import { DocumentUnion, OrganismType } from "../types/types";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DocsPerOrganisms from "../components/DocsPerOrganisms";
import { DocumentsContext } from "../contexts/DocumentsContext";
import File from "../components/File";
import HeaderSection from "../components/HeaderSection";
import { ReactComponent as IconReturn } from "../assets/img/icons/icon_arrow-Left.svg";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
import StatusAccompagnement from "../components/StatusAccompagnement";
import Table from "../components/Table";

const folder = {
    status: "En cours",
    title: "Passeport",
    id: 0,
};

const columns = [
    { key: "nationality", label: "" },
    { key: "type", label: "Type de document" },
    { key: "number", label: "Numéro" },
    { key: "createdAt", label: "Date de l'ajout" },
    { key: "status", label: "Statut" },
    { key: "isBlocked", label: "" },
];

// TO DELETE
const organisms: OrganismType[] = [
    {
        name: "Sofinco",
        logoPath:
            "https://www.suricats-consulting.com/wp-content/uploads/elementor/thumbs/logo-sofinco-pbeu3jvpnqo1sbdfhbo2bw9pbzxq2d59n16cg7w5ao.png",
    },
    {
        name: "Orange Bank",
        logoPath:
            "https://www.suricats-consulting.com/wp-content/uploads/elementor/thumbs/logo-sofinco-pbeu3jvpnqo1sbdfhbo2bw9pbzxq2d59n16cg7w5ao.png",
    },
    {
        name: "Fortuneo",
        logoPath:
            "https://www.suricats-consulting.com/wp-content/uploads/elementor/thumbs/logo-sofinco-pbeu3jvpnqo1sbdfhbo2bw9pbzxq2d59n16cg7w5ao.png",
    },
    {
        name: "La Poste",
        logoPath:
            "https://www.suricats-consulting.com/wp-content/uploads/elementor/thumbs/logo-sofinco-pbeu3jvpnqo1sbdfhbo2bw9pbzxq2d59n16cg7w5ao.png",
    },
];

const document = {
    name: "Déclaration de vol",
    path: "/img/cerfa_13753-04.pdf",
    receivedAt: "2021-01-01",
};

const Program = () => {
    const [selectedRows, setSelectedRows] = useState<DocumentUnion[]>([]);
    const { allDocuments } = useContext(DocumentsContext);
    const navigate = useNavigate();
    let { id } = useParams();
    const handleReturn = () => {
        navigate(-1);
    };

    const handleSelectionChange = (rows: DocumentUnion[]) => {
        setSelectedRows(rows);
    };

    return (
        <>
            <PageHeader>
                <h5>Mes accompagnements/{id}</h5>
            </PageHeader>
            <div className="content__body">
                <div className="row">
                    <div className="col-lg-9">
                        <IconReturn
                            fill="#5E89C2"
                            width="28px"
                            onClick={handleReturn}
                            className="pointer"
                        />
                        <StatusAccompagnement status={folder.status} />
                        <HeaderSection
                            title="Documents perdus/volés"
                            number=""
                        />
                        <Table
                            columns={columns}
                            rows={allDocuments}
                            onSelectionChange={handleSelectionChange}
                            selectedRows={selectedRows}
                            editable={false}
                        />

                        <DocsPerOrganisms organisms={organisms} />
                        <HeaderSection
                            title="Ma déclaration de perte ou de vol"
                            number="1"
                        />
                        <File file={document} />
                    </div>

                    <div className="col-lg-3">
                        <NotificationsList />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Program;
