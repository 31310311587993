/* eslint-disable react/style-prop-object */
import './style.scss';

import { Button_Style, Button_Type } from '../Button/Button';
import { FC, useContext, useState } from 'react';
import { getDocumentHighestAnalysisEver, getDocumentHighestAnalysisEverPermis } from '../../api/analysis';

import Accordion from '../Accordion';
import Alert from '../Alert';
import Button from '../Button';
import { DocumentBasylic } from '../../types/types';
import FileUploader from '../FileUploader';
import Select from '../Select';
import { UserContext } from '../../contexts/UserContext';

const options = [
  {
    value: "Carte d'identité",
    label: "Carte d'identité"
  },
  {
    value: "Passeport",
    label: "Passeport"
  },
  {
    value: "Permis de conduire",
    label: "Permis de conduire"
  },
  {
    value: "Titre de séjour",
    label: "Titre de séjour"
  },
];
type AddFolderFormProps = {
  onChangeType: (type: string) => void;
  type: string;
  setIsScanning: (isScanning: boolean) => void;
  isScanning?: boolean;
  setDocument: (document: DocumentBasylic) => void;
  setIsScanned?: (isScanned: boolean) => void;
};
const AddFolderForm: FC<AddFolderFormProps> = ({
  onChangeType,
  type,
  setIsScanning,
  isScanning = false,
  setDocument,
  setIsScanned
}) => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const { user } = useContext(UserContext)
  const userType = user?.type;

  const handleChangeFiles = (files: any) => {
    setSelectedFiles((prevSelectedFiles: any) => [...prevSelectedFiles, ...files]);
  }

  const handleFileRemoval = (file: File) => {
    const updatedFiles = selectedFiles.filter((selectedFile: any) => selectedFile !== file);
    setSelectedFiles(updatedFiles);
  };

  const handleVerification = async () => {
    setIsScanning(true);
    try {
      let response
      if (type === "Permis de conduire") {
        response = await getDocumentHighestAnalysisEverPermis(userType, selectedFiles);
      } else {
        response = await getDocumentHighestAnalysisEver(userType, selectedFiles);
      }

      setIsScanned && setIsScanned(true);
      setDocument && setDocument(response.data.success);
    } catch (error: any) {
      console.log(error)
      //  handleErrorVerification(error)
    } finally {
      setIsScanning(false);
    }
  };

  const renderDownloadButtons = () => {
    if (type === 'Passeport') {
      return (
        <FileUploader onChange={handleChangeFiles} onDelete={handleFileRemoval} title='Recto' fileLimit={1} maxFileSize={12} allowedFormats={'image/jpeg, image/png, application/pdf'}
        />
      );
    } else {
      return (
        <>
          <Alert type='info'>Un seul fichier est nécessaire si le recto/verso figure sur la même page.</Alert>
          <FileUploader onChange={handleChangeFiles} onDelete={handleFileRemoval} title='Recto/Verso' fileLimit={1} maxFileSize={12} allowedFormats={'image/jpeg, image/png, application/pdf'}
          />
          <FileUploader onChange={handleChangeFiles} onDelete={handleFileRemoval} title='Verso' fileLimit={1} maxFileSize={12} allowedFormats={'image/jpeg, image/png, application/pdf'}
          />
        </>
      )
    }
  };
  return (
    <div className="AddFolderForm card">
      <h4>Justificatif d'identité</h4>
      <p className='mb-1'>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam amet deserunt iure quas laboriosam, delectus odio assumenda nulla harum ipsam molestiae perspiciatis nam sint repellat quibusdam ducimus porro voluptatibus nemo?
      </p>
      <Select
        options={options}
        value={type}
        placeholder="Choisir un type de document"
        handleChange={onChangeType} />

      <div className="AddFolderForm__buttons">
        <Accordion title="Si vous utilisez votre téléphone">
          <ol>
            <li>Déposez la carte d'identité à plat sur une feuille blanche (assurez-vous d'être dans une pièce bien éclairée)</li>
            <li>Enlevez tous les stickers et autres objets collés sur le document qui pourraient entraver sa lecture.</li>
            <li>Prenez en photo ou scannez à l'aide de votre téléphone de manière à bien voir apparaître le document d'identité dans son intégralité</li>
            <li>Répétez la même opération pour le verso.
            </li>
          </ol>
        </Accordion>
        <Accordion title="Si vous utilisez un ordinateur">
          <ol>
            <li>Scannez le document avec un scanner bureautique.</li>
            <li>Enregistrer le ensuite sur la plateforme.</li>
          </ol>
          <small>NB : L'outil que nous vous mettons à disposition est développé à partir des pratiques bancaires. Il permet de confirmer l'authenticité des documents d'identité. La qualité de l'image jouant un rôle important dans sa vérification, nous vous conseillons de privilégier une version scannée de vos documents d'identité.</small>
        </Accordion>
        {type && renderDownloadButtons()}
      </div>
      <Button
        className='btn-add-folder'
        type={userType === 'partner' ? Button_Type.SECONDARY : Button_Type.PRIMARY}
        label={'Ajouter'}
        style={Button_Style.OUTLINED}
        disabled={(selectedFiles.length < 1)}
        onClick={() => handleVerification()} />
    </div>
  );
}

export default AddFolderForm;
