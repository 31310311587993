import { ReactComponent as IconCNI } from "../../assets/img/icons/icon_CNI.svg";
import { ReactComponent as IconDashboard } from "../../assets/img/icons/icon_dashboard.svg";
import { ReactComponent as IconFolder } from "../../assets/img/icons/icon_folder.svg";
import { ReactComponent as IconLock } from "../../assets/img/icons/icon_Lock.svg";
import { ReactComponent as IconVerification } from "../../assets/img/icons/icon_Verification.svg";

const iconProps = {
    fill: "#fff",
    width: "16px",
};

const links = [
    {
        url: "dashboard",
        label: "Tableau de bord",
        icon: <IconDashboard {...iconProps} />,
        hideInPartner: false,
        hideIfUnverified: false,
    },
    {
        url: "pieces-identite",
        label: "Pièces d'identité",
        icon: <IconCNI {...iconProps} />,
        hideInPartner: true,
        hideIfUnverified: false,
    },
    {
        url: "verifier-document",
        label: "Vérifier un document",
        icon: <IconVerification {...iconProps} />,
        hideInPartner: false,
        hideIfUnverified: false,
    },
    {
        url: "dark-web",
        label: "Dark Web",
        icon: <IconVerification {...iconProps} />,
        hideInPartner: false,
        hideIfUnverified: false,
    },
    {
        url: "filter",
        label: "Filigrane",
        icon: <IconLock {...iconProps} />,
        hideInPartner: false,
        hideIfUnverified: false,
    },
    {
        url: "mes-dossiers",
        label: "ID Locker",
        icon: <IconFolder {...iconProps} />,
        hideInPartner: false,
        hideIfUnverified: true,
    },
];

export default links;
