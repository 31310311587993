import './style.scss';

import { ButtonProps, Button_Style, Button_Type } from '../Button/Button';
import { FolderType, PartnerType } from '../../types/types';
import React, { FC, useContext, useEffect, useState } from 'react';

import Button from '../Button';
import { FolderContext } from '../../contexts/FoldersContext';
import Hug from '../Hug';
import IconAnonymous from "../../assets/img/icon_anonymous.svg";
import { ReactComponent as IconDelete } from '../../assets/img/icons/icon_Trash.svg';
import { ReactComponent as IconMore } from '../../assets/img/icons/icon_more.svg';
import Select from '../Select';
import { UserContext } from '../../contexts/UserContext';
import { changeInvitationDate } from '../../api/folder';
import { toast } from 'react-toastify';

const options = [
    {
        value: "1 jour",
        label: "1 jour"
    },
    {
        value: "3 jours",
        label: "3 jours"
    },
    {
        value: "5 jours",
        label: "5 jours"
    },
];
type PartnersCardProps = {
    partner: PartnerType
    folder: FolderType | undefined;
}

const PartnersCard: FC<PartnersCardProps> = ({ partner, folder }) => {
    const { tryRemoveFolderPermission, tryChangeInvitationDate } = useContext(FolderContext);
    const { user } = useContext(UserContext);
    const [valueDaysLeft, setValueDaysLeft] = useState("");
    const [seeMore, setSeeMore] = useState(false);
    const userType = user?.type;

    const calculateDaysRemaining = (expirationDate: string) => {
        const today = new Date();
        const expiration = new Date(expirationDate);
        const timeDiff = expiration.getTime() - today.getTime();
        const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        setValueDaysLeft(daysRemaining.toString())
    };

    useEffect(() => {
        calculateDaysRemaining(partner.expirationDate)
    }, [])

    const openMore = (e: React.MouseEvent) => {
        setSeeMore(!seeMore);
    }

    const handleRemoveAccess = async () => {
        const response = await tryRemoveFolderPermission(folder?.id, partner.id);
        if (response) {
            // À voir pour changer avec l'adresse email plutôt que le nom / prénom mais pas retourné dans l'objet partner
            toast.info(`Vous venez de retirez l'accès à ${partner.firstname} ${partner.lastname}`, {
                position: "top-center"
            })
        } else {
            toast.error(`Il y a eu un problème lors du retrait d'accès à ${partner.firstname} ${partner.lastname}, veuillez réessayer`, {
                position: "top-center"
            })
        }
    }

    const handleChangeDate = async (value: string) => {
        setValueDaysLeft(value.split(" ")[0]);
        await tryChangeInvitationDate(partner.authorizationId, Number(value.split(" ")[0]))
    }

    // To create Hug comp with any buttons you want
    const arrayButton: Array<ButtonProps> = [{
        className: "hug_delete",
        size: "S",
        label: "supprimer",
        style: Button_Style.SOLID,
        type: Button_Type.REVERSE_ERROR,
        icon: <IconDelete />,
        onClick: handleRemoveAccess
    }]


    return (
        <div className='partners_card'>
            <div className="partners_card_first">
                <div>
                    <img
                        src={IconAnonymous}
                        alt="img_profile_partner"
                    />
                </div>
                <div>{partner.firstname} {partner.lastname}</div>
            </div>
            <div className="partners_card_second">
                <Select
                    className="partners_card_second_select"
                    options={options}
                    value={`${valueDaysLeft} j`}
                    placeholder="Temps de validité"
                    handleChange={(value) => handleChangeDate(value)} />
                <Button icon={<IconMore />} type={Button_Type.PRIMARY} style={Button_Style.SOLID} size="more" iconHeight="30px" onClick={(e) => openMore(e)} />
                {seeMore ?
                    <Hug className="partners_card_second_more" arrayButton={arrayButton} /> :
                    <></>
                }
            </div>
        </div>
    );
}

export default PartnersCard;
