import React, { useState } from 'react'

import AddFolderForm from '../components/AddFolderForm';
import { DocumentBasylic } from '../types/types';
import DocumentIllustration from '../components/DocumentIllustration';
import ValidateFolder from '../components/ValidateFolder/ValidateFolder';

export default function AddFolder() {
	const [type, setType] = useState<string>('');
	const [isScanning, setIsScanning] = useState<boolean>(false);
	const [isScanned, setIsScanned] = useState<boolean>(false);

	const [document, setDocument] = useState<DocumentBasylic>();

	return (
		<div className='AddFolder h-100'>
			<div className="content__body h-100">
				<h3>Monter mon dossier</h3>
				<div className="AddFolder__row row">
					<div className="AddFolder__content col-sm-6">
						{!isScanned && !isScanning ? <AddFolderForm
							onChangeType={setType}
							type={type}
							setIsScanning={setIsScanning}
							isScanning={isScanning}
							setDocument={setDocument}
							setIsScanned={setIsScanned}
						/> : <ValidateFolder isScanning={isScanning} documentVerified={document} />}
					</div>
					<div className="AddFolder__illu col-sm-6">
						<DocumentIllustration type={type} />
					</div>
				</div>
			</div>
		</div>
	)
}
